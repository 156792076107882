import { CmsProductPage } from 'app/contexts/AppContext/types';
import { TaxonWithCmsPage } from './MainSection';

export const attachPageToTaxons = (taxons: TaxonWithCmsPage[], pages: CmsProductPage[]) => {
  const attachPage = (taxon: TaxonWithCmsPage) => {
    const page = pages.find(p => p.id === taxon.pageId);
    let updatedTaxon = { ...taxon };

    if (page) {
      updatedTaxon = { ...updatedTaxon, cmsPage: page };
    }
    if (updatedTaxon.taxons) {
      updatedTaxon = { ...updatedTaxon, taxons: updatedTaxon.taxons.map(attachPage) };
    }

    return updatedTaxon;
  };

  return taxons.map(attachPage);
};

export const getTaxonFromParams = (search: string) => {
  const queryParams = new URLSearchParams(search);
  return queryParams.get('taxon') || '';
};
