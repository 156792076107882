export type ResponsiveBreakpoints = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xl3' | 'xl4';

export const xs: number = 320;
export const sm: number = 530;
export const md: number = 768;
export const lg: number = 1024;
export const xl: number = 1280;
export const xxl: number = 1440;
export const xl3: number = 1536;
export const xl4: number = 1920;

export const responsiveSizes = { xs, sm, md, lg, xl, xxl, xl3, xl4 };

export const responsiveBreakpoint = (width: number): ResponsiveBreakpoints => {
  if (width < xs) {
    return 'xxs';
  }
  if (width < sm) {
    return 'xs';
  }
  if (width < md) {
    return 'sm';
  }
  if (width < lg) {
    return 'md';
  }
  if (width < xl) {
    return 'lg';
  }
  if (width < xxl) {
    return 'xl';
  }
  if (width < xl3) {
    return 'xxl';
  }
  if (width < xl4) {
    return 'xl3';
  }
  if (width >= xl4) {
    return 'xl4';
  }
  return 'xl';
};
