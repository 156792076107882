import { useRef, useState, useEffect } from 'react';

export const useScroll = selectedTabIndex => {
  const tabListRef = useRef(null);
  const [isScrolledLeft, setIsScrolledLeft] = useState(false);
  const [isScrolledRight, setIsScrolledRight] = useState(false);

  const checkScrollPosition = () => {
    const tabList = tabListRef.current;
    if (tabList) {
      setIsScrolledLeft(tabList.scrollLeft > 0);
      setIsScrolledRight(
        tabList.scrollWidth > tabList.clientWidth &&
          tabList.scrollLeft < tabList.scrollWidth - tabList.clientWidth,
      );
    }
  };

  useEffect(() => {
    const tabList = tabListRef.current;
    const selectedTab = tabList?.children[selectedTabIndex];
    if (tabList && selectedTab) {
      const scrollLeft = selectedTab.offsetLeft - tabList.offsetWidth / 2 + selectedTab.offsetWidth / 2;
      tabList.scrollTo({ left: scrollLeft, behavior: 'smooth' });
      checkScrollPosition();
    }
  }, [selectedTabIndex]);

  useEffect(() => {
    const tabList = tabListRef.current;
    if (tabList) {
      tabList.addEventListener('scroll', checkScrollPosition);
      checkScrollPosition();
    }

    return () => {
      if (tabList) {
        tabList.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, []);

  return { tabListRef, isScrolledLeft, isScrolledRight };
};
