import { useState, useEffect } from 'react';

const getTaxonFromParams = search => {
  const queryParams = new URLSearchParams(search);
  return queryParams.get('taxon') || '';
};

const clearTaxonFromParams = () => {
  const newUrl = window.location.pathname;
  window.history.replaceState({ path: newUrl }, '', newUrl);
};

const setTaxonInParams = taxonName => {
  // eslint-disable-next-line no-restricted-globals
  const queryParams = new URLSearchParams(location.search);
  queryParams.set('taxon', taxonName);
  // eslint-disable-next-line no-restricted-globals
  const newUrl = `${location.pathname}?${queryParams.toString()}`;
  window.history.replaceState(null, '', newUrl);
};

export const useTabSelection = (allTaxons, location) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const setSelectedTabByTaxonName = taxonName => {
    setTaxonInParams(taxonName);
    setSelectedTabIndex(taxonName ? allTaxons.findIndex(taxon => taxon.name === taxonName) + 1 : 0);
  };

  const setSelectedTabByIndex = index => {
    if (index === 0) {
      clearTaxonFromParams();
    } else {
      setTaxonInParams(allTaxons[index - 1].name);
    }
    setSelectedTabIndex(index);
  };

  useEffect(() => {
    const taxonFromParams = getTaxonFromParams(location.search);
    const index = taxonFromParams ? allTaxons.findIndex(taxon => taxon.name === taxonFromParams) + 1 : 0;
    setSelectedTabIndex(index);
  }, [allTaxons]);

  return { selectedTabIndex, setSelectedTabByTaxonName, setSelectedTabByIndex };
};
