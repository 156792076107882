import * as React from 'react';
import Card from 'styleguide/components/Card/Card';
import { responsiveBreakpoint, ResponsiveBreakpoints } from 'styleguide/styles/layout/responsive';
import useWindowDimensions from 'utils/dimensions';
import { useHistory } from 'react-router-dom';
import { TaxonWithCmsPage } from '../MainSection';
import HtmlContent from 'app/styleguide/components/HtmlContent/HtmlContent';

interface Props {
  taxon?: TaxonWithCmsPage;
}

const ProductWithInfoCard = ({ taxon }: Props): JSX.Element => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const currentBreakpoint: ResponsiveBreakpoints = responsiveBreakpoint(width);
  const getResponsiveProportion = (): number => {
    const proportionByBreakpoint = {
      xxs: 1.2,
      xs: 0.6,
      sm: 0.4,
      md: 0.7,
    };

    return proportionByBreakpoint[currentBreakpoint] || 0.9;
  };

  const bubbleHtml = taxon.cmsPage?.blueBadge;
  const TurnaroundTime = taxon.cmsPage?.turnaround ? (
    <HtmlContent content={taxon.cmsPage.turnaround} />
  ) : null;

  return (
    <Card
      className="mb-1 cursor-pointer"
      image={{
        url: taxon.image.url,
        alt: taxon.image.alt,
        width: 340,
        height: 306,
        responsiveProportion: getResponsiveProportion(),
      }}
      title={taxon.name}
      key={`${taxon.image.alt}`}
      linkText="Order Now"
      BubbleHtml={bubbleHtml}
      Body={TurnaroundTime}
      titleClassName="pt-5"
      linkUrl={taxon.pagePath}
      onClick={() => history.push(taxon.pagePath)}
    />
  );
};

export default ProductWithInfoCard;
